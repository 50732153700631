// migrated from the monolith

// Hide osano widget to use footer link instead ("Manage Cookies" button).
.osano-cm-widget {
  display: none;
}

.osano-cm-dialog {
  background-color: $color-background-primary;
  border-radius: $radius-lg;
  box-shadow: $shadow-sm;
  margin-bottom: $space-16;
  margin-left: $space-16;
  margin-right: $space-16;

  @include medium-up {
    margin-bottom: $space-24;
    margin-left: $space-24;
    margin-right: $space-24;
  }
}

// could also apply this to the buttons class for all buttons
// small primary denali button
.osano-cm-accept,
.osano-cm-deny,
.osano-cm-manage,
.osano-cm-save,
.osano-cm-accept-all,
.osano-cm-denyAll {
  @include text-100-bold;
  background-color: var(--color-interactive-primary);
  border-radius: $radius-round;
  border: $borders-weight-md solid transparent;
  color: var(--color-text-primary-inverse);
  min-height: 32px;
}

// using content includes the message and the link
.osano-cm-content {
  @include text-100;
}

.osano-cm-info {
  box-shadow: none;
}

.osano-cm-window {
  font-family: $primary-font;
  color: $color-text-primary;

  @media print {
    display: none; // hide for GiftCardPrint
  }
}

.osano-cm-disclosure__toggle {
  text-decoration: underline;
}

.osano-cm-close:focus-visible {
  outline: auto;
  outline-offset: 2px;
}

.osano-cm-info-dialog-header__close:focus {
  background-color: transparent;
  border-color: transparent;
  stroke: currentColor;
  stroke-width: 1px;
}

.osano-cm-save {
  bottom: $space-16;
  position: sticky;
}

.osano-cm-toggle__input:checked + .osano-cm-toggle__switch {
  background-color: var(--color-interactive-primary);
  border-color: var(--color-interactive-primary);
}

// Marketing, Personalization toggle
.osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch,
.osano-cm-toggle__input:checked:hover + .osano-cm-toggle__switch,
// Essential toggle
.osano-cm-toggle__input:disabled:checked:focus + .osano-cm-toggle__switch,
.osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch {
  background-color: var(--color-interactive-primary-hover);
  border-color: var(--color-interactive-primary-hover);
}

.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch {
  background-color: var(--color-interactive-primary-hover);
  border-color: var(--color-interactive-primary-hover);
}

.osano-cm-dialog--type_bar .osano-cm-dialog__buttons {
  margin-top: $space-8;

  @media screen and (min-width: 768px) {
    margin-right: 0.5em;
    margin-top: 0;
  }
}
