@import "@alltrails/shared/styles/landsEnd.scss";
/**
 * Generated file
 * Do not edit directly
 */

:root {
  --size-header-height-mobile: 4rem;
  --size-header-height-desktop: 4rem;
  --size-controls-lg: 3.5rem;
  --size-controls-md: 3rem;
  --size-controls-sm: 2.25rem;
  --size-icon-lg: 2rem;
  --size-icon-md: 1.5rem;
  --size-icon-sm: 1.25rem;
  --size-icon-xs: 1rem;
  --breakpoint-lg-margin: 5rem;
  --breakpoint-lg-size: 120rem;
  --breakpoint-md-margin: 3rem;
  --breakpoint-md-size: 70rem;
  --breakpoint-sm-margin: 1.5rem;
  --breakpoint-sm-size: 47.5rem;
  --breakpoint-xs-margin: 1rem;
  --breakpoint-xs-size: 22.5rem;
  --grid-page-max-width: 80rem;
  --guide-redwood-background: #9a4020;
  --guide-goldenrod-blockquote: #fdf3e2;
  --guide-goldenrod-background: #845b15;
  --guide-brown-blockquote: #f9dab4;
  --guide-maroon-blockquote: #ffe0e0;
  --guide-evergreen-blockquote: #ecf9ee;
  --guide-blackforest-blockquote: #ecf9ee;
  --guide-blackforest-background: #131e0e;
  --z-index-toast: 1000;
  --z-index-spinner: 900;
  --z-index-popover: 800;
  --z-index-modal: 400;
  --z-index-overlay: 300;
  --z-index-dropdown: 200;
  --z-index-sticky: 100;
  --z-index-default: 1;
  --z-index-deep: -99999;
  --timing-ease-in: cubic-bezier(0.15, 0, 0.15, 0);
  --timing-ease-out: cubic-bezier(0.32, 0.94, 0.6, 1);
  --timing-linear: cubic-bezier(0, 0, 1, 1);
  --duration-long: 2000ms;
  --duration-default: 500ms;
  --duration-short: 300ms;
  --space-1200: 6rem;
  --space-800: 4rem;
  --space-600: 3rem;
  --space-450: 2.25rem;
  --space-300: 1.5rem;
  --space-200: 1rem;
  --space-150: 0.75rem;
  --space-128: 8rem; /* investigate */
  --space-100: 0.5rem;
  --space-96: 6rem; /* deprecate */
  --space-64: 4rem; /* deprecate */
  --space-50: 0.25rem;
  --space-48: 3rem; /* deprecate */
  --space-40: 2.5rem; /* deprecate */
  --space-32: 2rem; /* deprecate */
  --space-24: 1.5rem; /* deprecate */
  --space-16: 1rem; /* deprecate */
  --space-12: 0.75rem; /* deprecate */
  --space-8: 0.5rem; /* deprecate */
  --space-4: 0.25rem; /* deprecate */
  --space-0: 0;
  --shadow-lg-spread: -0.0625rem;
  --shadow-lg-radius: 1rem;
  --shadow-lg-y: 0.5rem;
  --shadow-lg-x: 0;
  --shadow-md-spread: -0.125rem;
  --shadow-md-radius: 0.5rem;
  --shadow-md-y: 0.25rem;
  --shadow-md-x: 0;
  --shadow-sm-spread: -0.125rem;
  --shadow-sm-radius: 0.375rem;
  --shadow-sm-y: 0.0625rem;
  --shadow-sm-x: 0;
  --shadow-300: 0px 8px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --shadow-250: 0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  --shadow-200: 0px 4px 8px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --shadow-100: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  --radius-round: 624.9375rem;
  --radius-xl: 1.5rem;
  --radius-lg: 1rem;
  --radius-md: 0.5rem;
  --radius-sm: 0.25rem;
  --radius-none: 0;
  --font-letterspacing-400-responsive: -0.03125rem;
  --font-letterspacing-500-responsive: -0.03125rem;
  --font-letterspacing-600-responsive: -0.0625rem;
  --font-letterspacing-700-responsive: -0.0625rem;
  --font-letterspacing-700: -0.0625rem;
  --font-letterspacing-600: -0.03125rem;
  --font-letterspacing-500: -0.03125rem;
  --font-letterspacing-400: -0.015625rem;
  --font-size-700-responsive: 3.75rem;
  --font-size-600-responsive: 2.25rem;
  --font-size-500-responsive: 2rem;
  --font-size-400-responsive: 1.75rem;
  --font-size-700: 2.75rem;
  --font-size-600: 2rem;
  --font-size-500: 1.75rem;
  --font-size-400: 1.5rem;
  --font-size-300: 1.25rem;
  --font-size-200: 1rem;
  --font-size-100: 0.8125rem;
  --font-size-50: 0.6875rem;
  --font-lineheight-400-responsive: 1.2;
  --font-lineheight-500-responsive: 1.15;
  --font-lineheight-600-responsive: 1.1;
  --font-lineheight-700-responsive: 1.06;
  --font-lineheight-tight: 1.3; /* deprecate */
  --font-lineheight-default: 1.5;
  --font-lineheight-loose: 1.7; /* for use in paragraphs */
  --font-lineheight-700: 1.1;
  --font-lineheight-600: 1.15;
  --font-lineheight-500: 1.18;
  --font-lineheight-400: 1.2;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-family-secondary: Beatrice;
  --font-family-primary: Manrope;
  --color-plus-900: #171e40;
  --color-plus-700: #415096;
  --color-plus-600: #4563ff;
  --color-plus-500: #5772ff;
  --color-plus-300: #a3b2ff;
  --color-plus-100: #ebeeff;
  --color-orange-base: #f39d4f;
  --color-orange-300: #f0c389;
  --color-purple-base: #a386aa;
  --color-yellow-base: #eea82f;
  --color-yellow-900: #3a2a0e;
  --color-yellow-700: #925e0c;
  --color-yellow-300: #fccc78;
  --color-yellow-100: #fdf0d5;
  --color-blue-base: #51aec8;
  --color-blue-900: #19252a;
  --color-blue-700: #196b8d;
  --color-blue-300: #83c9dc;
  --color-blue-100: #e2eff2;
  --color-red-base: #f87559;
  --color-red-900: #38201a;
  --color-red-700: #b24b34;
  --color-red-300: #fdaa9b;
  --color-red-100: #ffe6df;
  --color-green-base: #51c152;
  --color-green-900: #1c2513;
  --color-green-700: #2f751a;
  --color-green-300: #a2e4a0;
  --color-green-100: #e9faec;
  --color-brand-pro: #e9501e; /* deprecate */
  --color-brand-light: #69a041; /* deprecate */
  --color-brand-darkest: #1e4304; /* deprecate */
  --color-brand-plus-dark: #4563ff;
  --color-brand-plus-light: #a3b2ff;
  --color-brand-lavender: #d7cadb;
  --color-brand-peach: #f9d9b3;
  --color-brand-cream: #f7f4ed;
  --color-brand-neon: #64f67b;
  --color-brand-dark: #142800;
  --color-brand-primary: #2c5601;
  --color-vendor-google: #4184f3;
  --color-vendor-facebook: #3c5997;
  --color-scrim-dark: rgba(0, 0, 0, 0.7);
  --color-scrim-light: rgba(0, 0, 0, 0.4);
  --color-dataviz-1: #3571ca;
  --color-conditions-steps: #c25c5b;
  --color-conditions-rubber: #9258ae;
  --color-conditions-plastic: #6174cb;
  --color-conditions-snow: #92d8f5;
  --color-conditions-metal: #acbec3;
  --color-conditions-paved: #555f52;
  --color-conditions-rock: #8a8783;
  --color-conditions-gravel: #d4d2bf;
  --color-conditions-sand: #e5bf79;
  --color-conditions-natural: #af8657;
  --color-conditions-wood: #805c5c;
  --color-conditions-grass: #85b767;
  --color-map-condition-outline: #000000; /* Border for polyline with conditions overlay */
  --color-map-custom-route-4-outline: #69546d;
  --color-map-custom-route-4: #a57ed8;
  --color-map-activity-outline: #24505e; /* Custom color. Border for activity */
  --color-map-activity: #219ad8; /* Represents users activity data, like the path a user has taken during an activity */
  --color-interactive-primary: #264311;
  --color-shadow: rgba(0, 0, 0, 0.3);
  --color-border-focus-default: rgba(101, 110, 94, 0.7); /* for web focus states that match default and non-primary ctas */
  --color-border-focus-primary: rgba(110, 221, 127, 0.7); /* for web focus states that match primary ctas */
  --color-background-primary-inverse-overlay: rgba(18, 18, 18, 0.85); /* use bg.inverse instead and set opacity separately */
  --color-background-primary-overlay: rgba(255, 255, 255, 0.8); /* For use in translucent buttons, toasts, tags etc. */
  --color-background-tinted-inverse: rgba(25, 32, 19, 0.08);
  --color-background-tinted: rgba(255, 255, 255, 0.7);
  --color-text-light-secondary: rgba(255, 255, 255, 0.7);
  --color-ui-highlight-hover: #4ade61;
  --color-neutral-transparent: rgba(0, 0, 0, 0);
  --color-neutral-black: #000000;
  --color-neutral-white: #ffffff;
  --color-neutral-900-a: rgba(21, 27, 13, 0.9);
  --color-neutral-800-a: rgba(21, 27, 13, 0.88);
  --color-neutral-700-a: rgba(21, 27, 13, 0.74);
  --color-neutral-600-a: rgba(21, 27, 13, 0.64);
  --color-neutral-500-a: rgba(21, 27, 13, 0.49);
  --color-neutral-400-a: rgba(21, 27, 13, 0.38);
  --color-neutral-300-a: rgba(21, 27, 13, 0.27);
  --color-neutral-200-a: rgba(21, 27, 13, 0.16);
  --color-neutral-100-a: rgba(21, 27, 13, 0.08);
  --color-neutral-50-a: rgba(21, 27, 13, 0.04);
  --color-neutral-950: #10130b; /* this is only for dark mode and shouldn't be used directly in light mode */
  --color-neutral-900: #1c2513;
  --color-neutral-800: #2b381f;
  --color-neutral-700: #4c5c43;
  --color-neutral-600: #656e5e;
  --color-neutral-500: #8b9182;
  --color-neutral-400: #a7a99f;
  --color-neutral-300: #c2c2b8;
  --color-neutral-200: #dbdad2;
  --color-neutral-100: #efefec;
  --color-neutral-50: #f6f6f4;
  --borders-weight-lg: 0.1875rem;
  --borders-weight-md: 0.125rem;
  --borders-weight-sm: 0.0625rem;
  --guide-midnight-blockquote: var(--color-blue-100);
  --guide-midnight-background: var(--color-blue-900);
  --guide-redwood-blockquote: var(--color-red-100);
  --guide-brown-background: var(--color-yellow-900);
  --guide-maroon-background: var(--color-red-900);
  --guide-evergreen-background: var(--color-brand-dark);
  --space-page-horizontal-mobile: var(--space-300);
  --button-radius-lg: var(--radius-round);
  --button-radius-md: var(--radius-round);
  --button-radius-sm: var(--radius-round);
  --radius-card: var(--radius-lg);
  --radius-drawer: var(--radius-lg);
  --color-orange-100: var(--color-brand-peach);
  --color-purple-100: var(--color-brand-lavender);
  --color-vendor-apple: var(--color-neutral-black);
  --color-map-poi-outline: var(--color-brand-primary); /* Border for POI */
  --color-map-poi: var(--color-green-100); /* when a trail intersects a POI */
  --color-map-custom-route-3-outline: var(--color-yellow-700);
  --color-map-custom-route-2-outline: var(--color-blue-700);
  --color-map-custom-route-1-outline: var(--color-red-700);
  --color-map-custom-route-3: var(--color-yellow-base);
  --color-map-custom-route-2: var(--color-blue-base);
  --color-map-custom-route-1: var(--color-red-base);
  --color-map-route-outline: var(--color-green-700); /* border for route */
  --color-badge-complete: var(--color-neutral-800);
  --color-uv-5: var(--color-purple-base);
  --color-uv-4: var(--color-red-base);
  --color-uv-3: var(--color-orange-base);
  --color-uv-2: var(--color-yellow-300);
  --color-uv-1: var(--color-green-300);
  --color-difficulty-hard: var(--color-red-700);
  --color-difficulty-moderate: var(--color-blue-700);
  --color-difficulty-easy: var(--color-green-700);
  --color-interactive-subtle-hover: var(--color-neutral-700); /* where used as foreground, deprecate for in favor of text tokens */
  --color-interactive-default-hover: var(--color-neutral-800); /* where used as foreground, deprecate for in favor of text tokens */
  --color-interactive-primary-hover: var(--color-neutral-800);
  --color-interactive-accent-hover: var(--color-green-base);
  --color-interactive-accent: var(--color-brand-dark); /* interactive items that become neon in dark mode */
  --color-border-interactive-hover: var(--color-neutral-400);
  --color-border-interactive-accent: var(--color-brand-dark);
  --color-border-interactive: var(--color-neutral-300);
  --color-border-separator: var(--color-neutral-200);
  --color-background-inverse: var(--color-brand-dark); /* this should replace bg primary inverse overlay */
  --color-background-accent-strong: var(--color-brand-dark);
  --color-background-accent-base: var(--color-brand-primary);
  --color-background-accent-weak: var(--color-brand-cream);
  --color-background-info-base: var(--color-blue-base);
  --color-background-info-weak: var(--color-blue-100);
  --color-background-caution-base: var(--color-yellow-base);
  --color-background-caution-weak: var(--color-yellow-100);
  --color-background-error-base: var(--color-red-700);
  --color-background-error-weak: var(--color-red-100);
  --color-background-success-base: var(--color-green-base);
  --color-background-success-weak: var(--color-green-100);
  --color-background-plus-secondary: var(--color-plus-300);
  --color-background-plus: var(--color-plus-100);
  --color-background-disabled: var(--color-neutral-50);
  --color-background-secondary-hover: var(--color-neutral-200);
  --color-background-secondary: var(--color-neutral-100);
  --color-background-primary-hover: var(--color-neutral-50);
  --color-background-primary: var(--color-neutral-white);
  --color-text-link-inverse-hover: var(--color-neutral-100); /* deprecate, use text tokens instead */
  --color-text-link-inverse: var(--color-neutral-white); /* deprecate, use text tokens instead */
  --color-text-dark: var(--color-brand-dark);
  --color-text-light: var(--color-neutral-white);
  --color-text-plus: var(--color-plus-600);
  --color-text-info: var(--color-blue-700);
  --color-text-caution: var(--color-yellow-700);
  --color-text-success: var(--color-green-700);
  --color-text-error: var(--color-red-700);
  --color-text-disabled: var(--color-neutral-200);
  --color-text-inverse-hover: var(--color-neutral-white);
  --color-text-primary-inverse: var(--color-neutral-white);
  --color-text-secondary-hover: var(--color-neutral-700);
  --color-text-secondary: var(--color-neutral-600);
  --color-text-primary-hover: var(--color-neutral-800);
  --color-text-primary: var(--color-brand-dark);
  --color-ui-highlight: var(--color-brand-neon);
  --color-map-route: var(--color-ui-highlight); /* Represents AllTrails specified route information, like route polyline, route elevation etc */
  --color-badge-verified: var(--color-badge-complete);
  --color-interactive-subtle: var(--color-text-secondary); /* where used as foreground, deprecate for in favor of text tokens */
  --color-interactive-default: var(--color-text-primary); /* where used as foreground, deprecate for in favor of text tokens */
  --color-border-error: var(--color-background-error-base);
  --color-border-disabled: var(--color-border-separator);
  --color-border-interactive-active: var(--color-text-primary);
  --color-text-link-secondary-hover: var(--color-interactive-default-hover); /* deprecate, use text tokens instead */
  --color-text-link-primary-hover: var(--color-interactive-default-hover);
  --color-text-placeholder: var(--color-text-secondary);
  --color-text-link-secondary: var(--color-interactive-default); /* deprecate, use text tokens instead */
  --color-text-link-primary: var(--color-interactive-default); /* deprecate, use text tokens instead */
}
